import React from 'react';

import styles from './home.module.css';

const Home = () => {
  return (
    <section className={styles.container}>
      <h2>Home screen.</h2>
    </section>
  );
};

export default Home;
